import Sidebar3 from "../components/Sidebar3";
import "./iframe.css";

function Iframe() {
  return (
    <>
      <Sidebar3
        headingText="Open Your Free Quantum Code Ai Account"
        provider_name="quantum"
      />
    </>
  );
}
export default Iframe;
