import Sidebar3 from "../components/Sidebar3";
import "./iframe.css";

function Iframe3() {
  return (
    <>
      <Sidebar3
        headingText="Open Your Free Ava Code Ai Account"
        provider_name="ava"
      />
    </>
  );
}
export default Iframe3;
