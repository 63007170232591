import { Helmet } from "react-helmet";
function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Trading Review Site</title>
        <meta
          name="description"
          content="Learn how we collect, use, and protect your personal information. Your privacy is our priority at Trading Review Site."
        />
      </Helmet>
      <div className="container">
        <h1>Privacy Policy</h1>
        <p>
          Welcome to our commitment to providing outstanding service to our
          valued clients. We recognize the significance of privacy and are
          dedicated to addressing any concerns visitors may have regarding the
          collection and use of information on our websites.
        </p>

        <h2>Corporate Privacy Policy</h2>

        <h3>Acceptance</h3>
        <p>
          This agreement becomes binding between the Buyer and Seller upon the
          Seller's written acknowledgment and confirmation or through the
          delivery of goods or services as specified herein. Acceptance is
          limited to the terms outlined in this document. The Buyer is not
          liable for any goods or services until a valid invoice is received at
          the designated "Bill To" address. Payment terms commence only upon
          receipt of an appropriate invoice referencing the purchase order
          number.
        </p>

        <h3>Entire Agreement</h3>
        <p>
          This document, including all terms and any attachments, constitutes
          the complete agreement between the Buyer and Seller, superseding any
          prior proposals or agreements. Any quotations or proposals from the
          Seller mentioned herein do not alter the terms unless explicitly
          stated. Documents from the Seller that present conflicting terms will
          not take precedence over the terms contained in this order. Changes
          require a written change order signed by an authorized Buyer
          representative.
        </p>

        <h3>Inspection</h3>
        <p>
          All goods delivered under this order are subject to inspection and
          must be approved within a reasonable timeframe following delivery,
          regardless of payment or prior review. Goods that do not meet
          specified standards may be returned to the Seller at their expense,
          along with liability for any related damages.
        </p>

        <h3>Replacements</h3>
        <p>
          Substitutions are not permitted without the explicit permission of the
          Buyer.
        </p>

        <h3>Warranty</h3>
        <p>
          The Seller guarantees that all goods are free from security interests,
          liens, or encumbrances and that they conform to the required
          standards. The Seller also affirms that the goods will match the
          specifications, drawings, or descriptions provided and meet quality
          benchmarks. These warranties are in addition to any other express or
          implied warranties and remain in effect after inspection, acceptance
          of delivery, and payment.
        </p>

        <h3>Warranty against Infringement</h3>
        <p>
          The Seller warrants that the sale or use of the specified products
          will not infringe on any patents, trademarks, or copyrights.
        </p>

        <h3>Packing</h3>
        <p>
          Each package must include a detailed packing slip with the purchase
          order number and comply with carrier guidelines. Additional charges
          are not permitted unless explicitly stated in the purchase order.
        </p>

        <h3>Compliance with Law</h3>
        <p>
          The Seller must fulfill this order in accordance with all relevant
          federal, state, and local laws. The Seller confirms they have obtained
          all necessary licenses, permits, and approvals, and adhere to the
          Buyer’s safety regulations.
        </p>

        <h3>Termination for Default</h3>
        <p>
          If the Seller fails to comply with essential conditions, the Buyer
          reserves the right to terminate further performance upon notification.
          In such cases, the Buyer has no obligations towards the Seller.
        </p>

        <h3>No Assignment</h3>
        <p>
          The Seller may not assign this purchase order without prior written
          consent from the Buyer.
        </p>

        <h3>Governing Law</h3>
        <p>
          This order is governed by the laws of the state where the Buyer’s
          premises are located, and both parties agree to the exclusive
          jurisdiction and venue of the state and federal courts in that state.
        </p>

        <h3>Equal Employment Opportunity</h3>
        <p>
          This order includes the provisions of Executive Order 11246 (as
          amended), with the Seller confirming compliance, except where
          exempted.
        </p>

        <h3>License</h3>
        <p>
          If software is involved, the Seller grants the Buyer a perpetual,
          irrevocable license, including updates at no additional cost, and
          assures rightful ownership and authority to grant such a license.
        </p>

        <h3>Confidentiality</h3>
        <p>
          The details and existence of this order, as well as related
          information, must be kept confidential. Disclosure of this purchase
          order by the Seller requires prior written consent from the Buyer.
        </p>

        <h3>General</h3>
        <p>
          If any clause is found to be invalid, it does not affect the validity
          of the remaining terms. A waiver of any breach by the Buyer does not
          imply a waiver of future breaches. A term is not considered waived
          unless documented in writing and signed by an authorized officer of
          the Buyer.
        </p>
      </div>
    </>
  );
}
export default Privacy;
