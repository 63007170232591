import "./index.css";
function TradeImage() {
  return (
    <>
      <div className="TradeImage">
        <div class="Home_download_caption__qqqqi">
          Trustworthy
          <span class="Home_download_caption_green__ZNiyr"> Trading </span>
          Platform
        </div>
        <img
          src="https://coinswitch.co/_next/static/media/hero_mobile.2b84759a.png"
          alt=""
        />
      </div>
    </>
  );
}
export default TradeImage;
