import Sidebar3 from "../components/Sidebar3";
import "./iframe.css";

function Iframe4() {
  return (
    <>
      <Sidebar3
        headingText="Open Your Free Plus500 Code Ai Account"
        provider_name="plus500"
      />
    </>
  );
}
export default Iframe4;
