import Sidebar3 from "../components/Sidebar3";
import "./iframe.css";

function Iframe2() {
  return (
    <>
      <Sidebar3
        headingText="Open Your Free Activtrades Code Ai Account"
        provider_name="activtrades"
      />
    </>
  );
}
export default Iframe2;
